<template>
  <v-row v-scroll="onScroll">
    <!-- header -->
    <mini-header title="Crear documento" :dense="true" :breadcrumbs="breadcrumbs" :hiddenTab="true" :addPadding="true" :scroll="scroll" />
    <!-- end header -->
    <v-col cols="12" class="mt-20 px-0" v-if="loadingDetail">
      <SkeletonLoaderDocumentCreate />
    </v-col>
    <template v-else>
      <v-col v-if="checkPermissionDTE">
        <PermissionDenied />
      </v-col>
      <v-col class="mt-20 pa-0" v-else style="z-index:0">
        <v-col cols="12" class="px-0" v-if="$route.name === 'DocumentsClone'">
          <v-alert class="mb-n1" text outlined color="var(--light-yellow-500)" rounded>
            <div class="d-flex pa-0">
              <v-icon color="var(--light-yellow-500)">mdi-file-remove</v-icon>
              <div class="ml-3 alert-description">
                <span class="body-1 font-weight-bold">Creación de documento</span>
                <span class="d-block body-2">Estás creando {{documentParent === documents.document_type ? 'un' : 'una'}}
                  <span v-if="documentParent !== documents.document_type " class="font-weight-bold">{{ ((activeDocument || {}).name || '') | lower }}</span>
                  <template v-if="['CL56','CL61', 'CL112', 'CL111'].includes(this.$route.query.document_type) && hiddenAction && $route.query.action">
                    que  <span class="font-weight-bold">{{ ((overrideType.find((item) => item.id.toString() === this.$route.query.action) || {}).name || ''
                    ) | lower }}</span>
                  </template>
                  {{documentParent === documents.document_type ? 'documento' : ''}} a partir de la <span class="font-weight-bold">
                  {{ (documentTypes.find(d => d.id === documentParent) || {}).name }}
                  N° {{ documents.number }} </span></span>
              </div>
            </div>
          </v-alert>
        </v-col>
        <v-col cols="12" class="pb-8 px-0">
          <!-- document inf-->
          <v-expansion-panels v-model="panelInf" :accordion="false">
            <v-expansion-panel>
              <v-expansion-panel-header class="px-5 py-8">
                <v-row no-gutters align="center">
                  <v-col class="pa-0">
                    <span class="subtitle-2 grey-700--text font-weight-bold d-flex align-center"><v-icon class="mr-2" size="18" color="grey-700">mdi-information-outline</v-icon>Información del documento</span>
                  </v-col>
                </v-row>
              </v-expansion-panel-header>
              <v-expansion-panel-content class="px-7 pb-8">
                <v-col cols="12" class="pa-0 mt-n2">
                  <v-list class="transparent pa-0">
                    <v-row align="center">
                      <div style="width:273px !important" class="pa-0 mx-2">
                        <v-list-item class="pa-0">
                          <v-list-item-content>
                            <v-list-item-title class="grey-500--text">Tipo de documento</v-list-item-title>
                            <v-list-item-subtitle class="mt-1">
                              <v-autocomplete
                                v-model="$v.documents.document_type.$model"
                                :error="$v.documents.document_type.$error"
                                placeholder="Selecciona un tipo de documento"
                                :items="currentDocumentList"
                                item-value="id"
                                item-text="name"
                                :menu-props="{ overflowY: true, transition: 'slide-y-transition' }"
                                height="40"
                                single-line
                                outlined
                                dense
                                hide-details
                                append-icon="mdi-chevron-down"
                              >
                                <template v-slot:item="data">
                                  <v-list-item-title class="font-weight-regular">{{data.item.name}}</v-list-item-title>
                                </template>
                                <template v-slot:selection="data">
                                  <v-list-item-title class="grey-500--text" style="min-width: 220px !important">{{data.item.name}}</v-list-item-title>
                                </template>
                              </v-autocomplete>
                            </v-list-item-subtitle>
                          </v-list-item-content>
                        </v-list-item>
                      </div>
                      <div class="pa-0 mx-2" style="width:173px !important">
                        <v-list-item class="pa-0">
                          <v-list-item-content>
                            <v-list-item-title class="grey-500--text">Fecha de emisión</v-list-item-title>
                            <v-list-item-subtitle class="mt-1">
                              <v-menu v-model="datePickerMenuDocumentsDate" :close-on-content-click="false" offset-y :nudge-bottom="10" transition="slide-y-transition" max-width="290">
                                <template v-slot:activator="{ on, attrs }">
                                  <v-text-field v-model="$v.documents.date.$model" :error="$v.documents.date.$error" v-bind="attrs" v-on="on" append-icon="mdi-calendar-blank" placeholder="dd-mm-aaaa" outlined single-line dense readonly hide-details />
                                </template>
                                <v-date-picker v-model="$v.documents.date.$model" @input="datePickerMenuDocumentsDate=false" min="2020-01-01" no-title :first-day-of-week="1" type="date" locale="es" max-width="290" color="blue-500">
                                  <v-row class="mb-2 px-6" align="center" justify="end">
                                    <v-btn class="body-1" :ripple="false" text color="blue-500" @click="() => { documents.date=null; datePickerMenuDocumentsDate=false }">Limpiar</v-btn>
                                    <v-spacer />
                                    <v-btn @click="() => { $v.documents.date.$model=today; datePickerMenuDocumentsDate=false }" color="blue-500">Hoy</v-btn>
                                  </v-row>
                                </v-date-picker>
                              </v-menu>
                            </v-list-item-subtitle>
                          </v-list-item-content>
                        </v-list-item>
                      </div>
                      <div class="pa-0 mx-2" style="width:173px !important">
                        <v-list-item class="pa-0">
                          <v-list-item-content>
                            <v-list-item-title class="grey-500--text">Fecha de vencimiento</v-list-item-title>
                            <v-list-item-subtitle class="mt-1">
                              <v-menu v-model="datePickerMenuDocumentsDueDate" :close-on-content-click="false" offset-y :nudge-bottom="10" transition="slide-y-transition" max-width="280">
                                <template v-slot:activator="{ on, attrs }">
                                  <v-text-field v-model="documents.due_date" placeholder="dd-mm-aaaa" v-bind="attrs" v-on="on" append-icon="mdi-calendar-blank" outlined single-line dense readonly hide-details />
                                </template>
                                <v-date-picker v-model="documents.due_date" @input="datePickerMenuDocumentsDueDate=false" min="2020-01-01" no-title :first-day-of-week="1" type="date" locale="es" max-width="290" color="blue-500">
                                  <v-row class="mb-2 px-6" align="center" justify="end">
                                    <v-btn class="body-1" :ripple="false" text color="blue-500" @click="() => { documents.due_date=null; datePickerMenuDocumentsDueDate=false }">Limpiar</v-btn>
                                    <v-spacer />
                                    <v-btn @click="() => { documents.due_date=today; datePickerMenuDocumentsDueDate=false }" color="blue-500">Hoy</v-btn>
                                  </v-row>
                                </v-date-picker>
                              </v-menu>
                            </v-list-item-subtitle>
                          </v-list-item-content>
                        </v-list-item>
                      </div>
                      <v-col class="pa-0 mx-2" v-if="$store?.state?.auth?.account?.allowed_to_assign" style="min-width:150px !important;">
                        <v-list-item class="pa-0">
                          <v-list-item-content>
                            <v-list-item-title class="grey-500--text">{{$t('generals.Folio')}}</v-list-item-title>
                            <v-list-item-subtitle class="mt-1">
                              <div>
                                <v-text-field v-model.trim="$v.documents.number.$model" :error="$v.documents.number.$error" placeholder="Indica el N° de folio" outlined single-line dense hide-details />
                              </div>
                            </v-list-item-subtitle>
                          </v-list-item-content>
                        </v-list-item>
                      </v-col>
                      <div class="pa-0 mt-n6 d-flex ml-2" style="width:280px !important;">
                        <v-list-item class="pa-0 ma-0">
                          <v-list-item-content>
                            <v-list-item-title class="d-flex align-center grey-500--text">Selecciona tu método de pago</v-list-item-title>
                            <v-list-item-subtitle class="mt-5">
                              <v-radio-group class="mt-n2 payment-radio pa-0" v-model="documents.collection_method" row absolute style="position:absolute" dense hide-details>
                                <v-radio label="Crédito" :value="2" />
                                <v-radio label="Contado" :value="1" />
                                <v-radio label="Sin costo" :value="3" />
                              </v-radio-group>
                            </v-list-item-subtitle>
                          </v-list-item-content>
                        </v-list-item>
                      </div>
                      <v-col :cols="$store?.state?.auth?.account?.allowed_to_assign ? 2 : ''" class="pa-0 mx-2" v-if="$helpers.hasSomePermission(['view_branch']) && branchList?.length" style="min-width:130px !important;">
                        <v-list-item class="pa-0">
                          <v-list-item-content>
                            <v-list-item-title class="grey-500--text d-flex align-center">Tienda<v-chip class="ml-1 pa-2 caption grey-700--text" x-small color="grey-background">Opcional</v-chip></v-list-item-title>
                            <v-list-item-subtitle class="mt-1">
                              <v-autocomplete v-model="documents.issuer.branch" label="Selecciona una tienda" :items="branchList" item-value="name" item-text="name" :menu-props="{ overflowY: true, transition: 'slide-y-transition', maxWidth: '300px' }" height="40" single-line outlined dense hide-details append-icon="mdi-chevron-down">
                                <template v-slot:item="data">
                                  <v-list-item-title class="font-weight-regular">{{data.item.name}}</v-list-item-title>
                                </template>
                              </v-autocomplete>
                            </v-list-item-subtitle>
                          </v-list-item-content>
                        </v-list-item>
                      </v-col>
                      <v-col class="pa-0 mx-2" style="min-width:400px !important;">
                        <v-list-item class="pa-0">
                          <v-list-item-content>
                            <v-list-item-title class="grey-500--text">Glosa</v-list-item-title>
                            <v-list-item-subtitle class="mt-1">
                              <v-text-field v-model="documents.note" oninput="this.value=this.value.replace(/[^a-zA-Z0-9,. ]/g,'');" hide-details outlined required single-line dense />
                            </v-list-item-subtitle>
                          </v-list-item-content>
                        </v-list-item>
                      </v-col>
                      <div class="pa-0 mx-2" style="width:370px !important;">
                        <v-row no-gutters>
                          <v-col class="pa-0">
                            <v-list-item class="pa-0">
                              <v-list-item-content>
                                <v-list-item-title class="d-flex align-center grey-500--text">Periodo de emisión<v-chip class="ml-1 pa-2 caption grey-700--text" x-small color="grey-background">Opcional</v-chip></v-list-item-title>
                                <v-list-item-subtitle class="mt-1">
                                  <v-row no-gutters>
                                    <div class="mr-2" style="width:173px !important">
                                      <v-menu v-model="datePickerMenuDocumentsPeriodStart" :close-on-content-click="false" offset-y  transition="slide-y-transition" max-width="290" z-index="1000">
                                        <template v-slot:activator="{ on, attrs }">
                                          <v-text-field v-model="$v.documents.period_start.$model" :error="$v.documents.period_end.$error" v-bind="attrs" v-on="on" append-icon="mdi-calendar-blank" placeholder="dd-mm-aaaa" prefix="desde" outlined single-line dense readonly hide-details />
                                        </template>
                                        <v-date-picker v-model="$v.documents.period_start.$model" :error="$v.documents.period_end.$error" @input="datePickerMenuDocumentsPeriodStart=false" no-title :first-day-of-week="1" type="date" locale="es" width="290" color="blue-500">
                                          <v-row class="pt-0 pb-5 px-6" align="center" justify="end">
                                            <v-btn class="body-1" :ripple="false" text color="blue-500" @click="() => { $v.documents.period_start.$model=null; datePickerMenuDocumentsPeriodStart=false }">Limpiar</v-btn>
                                            <v-spacer />
                                            <v-btn @click="() => { $v.documents.period_start.$model=today; datePickerMenuDocumentsPeriodStart=false }" color="blue-500">Hoy</v-btn>
                                          </v-row>
                                        </v-date-picker>
                                      </v-menu>
                                    </div>
                                    <div class="mx-2" style="width:173px !important">
                                      <v-menu v-model="datePickerMenuDocumentsPeriodEnd" :close-on-content-click="false" offset-y transition="slide-y-transition" max-width="290" z-index="1000">
                                        <template v-slot:activator="{ on, attrs }">
                                          <v-text-field class="pa-0" v-model="$v.documents.period_end.$model" :error="$v.documents.period_start.$error" v-bind="attrs" v-on="on" append-icon="mdi-calendar-blank" placeholder="dd-mm-aaaa" prefix="hasta" outlined single-line dense readonly hide-details />
                                        </template>
                                        <v-date-picker v-model="$v.documents.period_end.$model" @input="datePickerMenuDocumentsPeriodEnd=false" no-title :first-day-of-week="1" type="date" locale="es" width="290" color="blue-500" :min="documents.period_start">
                                          <v-row class="pt-0 pb-5 px-6" align="center" justify="end">
                                            <v-btn class="body-1" :ripple="false" text color="blue-500" @click="() => { $v.documents.period_end.$model=null; datePickerMenuDocumentsPeriodEnd=false }">Limpiar</v-btn>
                                            <v-spacer />
                                            <v-btn @click="() => { $v.documents.period_end.$model=today; datePickerMenuDocumentsPeriodEnd=false }" color="blue-500">Hoy</v-btn>
                                          </v-row>
                                        </v-date-picker>
                                      </v-menu>
                                    </div>
                                  </v-row>
                                </v-list-item-subtitle>
                              </v-list-item-content>
                            </v-list-item>
                          </v-col>
                        </v-row>
                      </div>
                    </v-row>
                  </v-list>
                </v-col>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
          <!-- end document inf -->

          <!-- receiver inf -->
          <v-expansion-panels class="mt-5" v-model="panelReceiver">
            <v-expansion-panel>
              <v-expansion-panel-header class="px-5 py-8">
                <v-row align="center">
                  <v-col class="pa-0 pl-3">
                    <span class="subtitle-2 grey-700--text font-weight-bold d-flex align-center"><v-icon class="mr-2" size="18" color="grey-700">mdi-account </v-icon>Información del receptor</span>
                  </v-col>
                  <v-spacer />
                  <v-row class="pr-5" align="center" justify="end" no-gutters>
                    <v-btn @click.stop="dialogCustomer=true" v-if="customerAditionals?.isCustomer" text color="blue-500"><v-icon size="20">mdi-pencil</v-icon></v-btn>
                  </v-row>
                </v-row>
              </v-expansion-panel-header>
              <v-expansion-panel-content class="px-10 pb-10">
                <v-row class="mt-n2">
                  <v-col cols="12" class="pa-0">
                    <v-list class="transparent pa-0">
                      <v-row>
                        <v-col cols="2" class="pa-0 mx-2">
                          <v-list-item class="pa-0">
                            <v-list-item-content>
                              <v-list-item-title class="grey-500--text">{{$t('generals.RUT')}}</v-list-item-title>
                              <v-list-item-subtitle class="mt-1">
                                <v-text-field v-model.trim="$v.documents.customer.taxpayer.$model" :error="$v.documents.customer.taxpayer.$error" :loading="customerLoading ? 'green-500' : false" v-dni="documents.customer.country" placeholder="Ej: 9605009-7" outlined single-line dense hide-details maxlength="16" />
                              </v-list-item-subtitle>
                            </v-list-item-content>
                          </v-list-item>
                        </v-col>
                        <v-col cols="5" class="pa-0 mx-2">
                          <v-list-item class="pa-0">
                            <v-list-item-content>
                              <v-list-item-title class="grey-500--text">Razón social</v-list-item-title>
                              <v-list-item-subtitle class="mt-1">
                                <v-text-field v-model.trim="$v.documents.customer.name.$model" :error="$v.documents.customer.name.$error" oninput="this.value=this.value.replace(/[^a-zA-Z0-9 ]/g,'');" outlined required single-line dense maxlength="100" hide-details />
                              </v-list-item-subtitle>
                            </v-list-item-content>
                          </v-list-item>
                        </v-col>
                        <v-col class="pa-0 mx-2" v-if="$store.getters['base/currentCountry'] === 'CL'">
                          <v-list-item class="pa-0">
                            <v-list-item-content>
                              <v-list-item-title class="grey-500--text">Giro</v-list-item-title>
                              <v-list-item-subtitle class="mt-1">
                                <v-text-field
                                  v-model="$v.documents.customer.business_activity.$model"
                                  :error="$v.documents.customer.business_activity.$error"
                                  v-if="!(customerAditionals?.business_activities?.length && customerAditionals.isCustomer)"
                                  oninput="this.value=this.value.replace(/[^a-zA-Z0-9 ]/g,'');"
                                  outlined
                                  required
                                  single-line
                                  dense
                                  maxlength="40"
                                  hide-details
                                />
                                <v-autocomplete
                                  v-model="$v.documents.customer.business_activity.$model"
                                  :error="$v.documents.customer.business_activity.$error"
                                  :items="customerAditionals.business_activities"
                                  v-else
                                  single-line
                                  hide-details
                                  height="40"
                                  outlined
                                  dense
                                  append-icon="mdi-chevron-down"
                                />
                              </v-list-item-subtitle>
                            </v-list-item-content>
                          </v-list-item>
                        </v-col>
                      </v-row>
                      <v-row class="mt-n2">
                        <v-col cols="5" class="pa-0 mx-2">
                          <v-list-item class="pa-0">
                            <v-list-item-content>
                              <v-list-item-title class="grey-500--text">Dirección</v-list-item-title>
                              <v-list-item-subtitle class="mt-1">
                                <v-text-field
                                  v-model.trim="$v.documents.customer.address.$model"
                                  :error="$v.documents.customer.address.$error"
                                  v-if="!(customerAditionals?.extra_addresses?.length && customerAditionals.isCustomer)"
                                  oninput="this.value=this.value.replace(/[^a-zA-Z0-9,. ]/g,'');"
                                  outlined
                                  required
                                  single-line
                                  dense
                                  maxlength="64"
                                  hide-details
                                />
                                <v-autocomplete
                                  v-model="$v.documents.customer.address.$model"
                                  :error="$v.documents.customer.address.$error"
                                  v-else
                                  :items="customerAditionals.extra_addresses.map((item) => item[0])"
                                  single-line
                                  hide-details
                                  height="40"
                                  outlined
                                  dense
                                  append-icon="mdi-chevron-down"
                                  @change="documents.customer.state = customerAditionals.extra_addresses?.find((item) => item[0] === documents.customer.address)[1] ?? ''"
                                >
                                  <template v-slot:selection="data">
                                    <v-list-item-title class="grey-500--text" style="min-width: 93% !important">{{data.item}}</v-list-item-title>
                                  </template>
                                </v-autocomplete>
                              </v-list-item-subtitle>
                            </v-list-item-content>
                          </v-list-item>
                        </v-col>
                        <v-col cols="3" class="pa-0 mx-2" v-if="['CL110', 'CL111', 'CL112'].includes(documents.document_type)">
                          <v-list-item class="pa-0">
                            <v-list-item-content>
                              <v-list-item-title class="grey-500--text">País</v-list-item-title>
                              <v-list-item-subtitle class="mt-1">
                                <v-autocomplete v-model="$v.documents.customer.country.$model" :error="$v.documents.customer.country.$error" :items="simplifiedCountryList" item-value="id" item-text="name" append-icon="mdi-chevron-down" single-line hide-details dense height="40" outlined no-data-text="No existen países." />
                              </v-list-item-subtitle>
                            </v-list-item-content>
                          </v-list-item>
                        </v-col>
                        <v-col cols="3" class="pa-0 mx-2" v-if="!['CL110', 'CL111', 'CL112'].includes(documents.document_type)">
                          <v-list-item class="pa-0">
                            <v-list-item-content>
                              <v-list-item-title class="grey-500--text">{{$t('generals.Comuna')}}</v-list-item-title>
                              <v-list-item-subtitle class="mt-1">
                                <v-autocomplete v-model="$v.documents.customer.state.$model" :error="$v.documents.customer.state.$error" :items="$store.getters['base/currentStates'] || []" item-value="name" item-text="name" single-line hide-details dense height="40" outlined placeholder="Selecciona la comuna" no-data-text="No existen datos" append-icon="mdi-chevron-down" />
                              </v-list-item-subtitle>
                            </v-list-item-content>
                          </v-list-item>
                        </v-col>
                      </v-row>
                      <v-row class="mt-n2">
                        <v-col cols="5" class="pa-0 mx-2">
                          <v-list-item class="pa-0">
                            <v-list-item-content>
                              <v-list-item-title class="d-flex align-center grey-500--text">Email<v-chip class="ml-1 pa-2 caption grey-700--text" x-small color="grey-background">Opcional</v-chip></v-list-item-title>
                              <v-list-item-subtitle class="mt-1">
                                <v-text-field
                                  v-model.trim="$v.documents.customer.email.$model"
                                  :error="$v.documents.customer.email.$error"
                                  v-if="!(customerAditionals?.contacts?.length && customerAditionals.isCustomer)"
                                  outlined
                                  required
                                  single-line
                                  dense
                                  maxlength="64"
                                  hide-details
                                />
                                <v-autocomplete
                                  v-model="documents.customer.contacts"
                                  :items="customerAditionals.contacts"
                                  v-else
                                  single-line
                                  hide-details
                                  dense
                                  height="40"
                                  outlined
                                  append-icon="mdi-chevron-down"
                                  no-data-text="No existen emails."
                                />
                              </v-list-item-subtitle>
                            </v-list-item-content>
                          </v-list-item>
                        </v-col>
                      </v-row>
                    </v-list>
                  </v-col>
                </v-row>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
          <!-- end receiver inf -->

          <!-- export inf -->
          <v-expansion-panels class="mt-5" v-model="panelExport" v-if="['CL110', 'CL111', 'CL112'].includes(documents.document_type)">
            <v-expansion-panel>
              <v-expansion-panel-header class="px-5 py-8">
                <v-row no-gutters align="center">
                  <v-col class="pa-0">
                    <span class="subtitle-2 grey-700--text font-weight-semibold d-flex align-center"><v-icon class="mr-2" size="18" color="grey-700">mdi-earth</v-icon>Información de la exportación</span>
                  </v-col>
                </v-row>
              </v-expansion-panel-header>
              <v-expansion-panel-content class="px-0 pb-5">
                <v-row class="mt-n2" no-gutters>
                  <v-col cols="12" class="py-0 px-5">
                    <v-list class="transparent pa-0">
                      <v-row no-gutters>
                        <v-col cols="3" class="pa-0 mr-5">
                          <v-list-item class="pa-0">
                            <v-list-item-content>
                              <v-list-item-title class="grey-500--text">Tipo de moneda</v-list-item-title>
                              <v-list-item-subtitle class="mt-1">
                                <v-autocomplete v-model.trim="$v.exchange.currency_from.$model" :error="$v.exchange.currency_from.$error" :items="activeDocument.currencies" item-value="id" :menu-props="{ overflowY: true, transition: 'slide-y-transition' }" height="40" single-line outlined dense hide-details no-data-text="No existen monedas disponibles." append-icon="mdi-chevron-down" placeholder="Selecciona el tipo de moneda">
                                <template v-slot:item="data">
                                  <v-list-item-title class="font-weight-regular">{{data.item}} - {{currencies?.find(c => c.id === data.item)?.name || 'Otras monedas'}}</v-list-item-title>
                                </template>
                                </v-autocomplete>
                              </v-list-item-subtitle>
                            </v-list-item-content>
                          </v-list-item>
                        </v-col>
                        <v-col cols="2" class="pa-0" v-if="$store.getters['base/currentCountry'] === 'CL'">
                          <v-list-item class="pa-0">
                            <v-list-item-content>
                              <v-list-item-title class="grey-500--text">Tipo de cambio</v-list-item-title>
                              <v-list-item-subtitle class="mt-1">
                                <v-text-field v-model.number="$v.exchange.rate.$model" :error="$v.exchange.rate.$error" oninput="this.value=this.value.replace(/[^0-9.]/g,'');" required maxlength="12" single-line reverse outlined flat dense hide-details />
                              </v-list-item-subtitle>
                            </v-list-item-content>
                          </v-list-item>
                        </v-col>
                      </v-row>
                    </v-list>
                  </v-col>
                </v-row>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
          <!-- end export inf -->

          <!-- shipping inf -->
          <v-expansion-panels class="mt-5" v-model="panelShipping" v-if="documents.document_type === 'CL52'">
            <v-expansion-panel>
              <v-expansion-panel-header class="px-5 py-8">
                <v-row no-gutters align="center">
                  <v-col class="pa-0">
                    <span class="subtitle-2 grey-700--text font-weight-bold d-flex align-center"><v-icon class="mr-2" size="18" color="grey-700">mdi-truck</v-icon>Información de envío</span>
                  </v-col>
                </v-row>
              </v-expansion-panel-header>
              <v-expansion-panel-content class="px-0 pb-5">
                <v-row class="mt-n2" no-gutters>
                  <v-col cols="12" class="py-0 px-5">
                    <v-list class="transparent pa-0">
                      <v-col cols="3" class="pa-0">
                        <v-list-item class="pa-0">
                          <v-list-item-content>
                            <v-list-item-title class="grey-500--text">Tipo de envío</v-list-item-title>
                            <v-list-item-subtitle class="mt-1">
                              <v-select v-model="$v.shipping.type.$model" :error="$v.shipping.type.$error" :items="shippingTypes" item-text="label" item-value="value" :menu-props="{ overflowY: true, transition: 'slide-y-transition' }" placeholder="Selecciona el tipo de envío" height="40" single-line outlined dense hide-details append-icon="mdi-chevron-down" />
                            </v-list-item-subtitle>
                          </v-list-item-content>
                        </v-list-item>
                      </v-col>
                      <v-row class="mt-n2" no-gutters>
                        <v-col cols="5" class="pa-0">
                          <v-list-item class="pa-0">
                            <v-list-item-content>
                              <v-list-item-title class="grey-500--text">Nombre del conductor</v-list-item-title>
                              <v-list-item-subtitle class="mt-1">
                                <v-text-field v-model="$v.shipping.carrier_name.$model" :error="$v.shipping.carrier_name.$error" oninput="this.value=this.value.replace(/[^a-zA-Z0-9 ]/g,'');" outlined single-line dense maxlength="30" hide-details />
                              </v-list-item-subtitle>
                            </v-list-item-content>
                          </v-list-item>
                        </v-col>
                        <v-col cols="2" class="pa-0 mx-3">
                          <v-list-item class="pa-0">
                            <v-list-item-content>
                              <v-list-item-title class="grey-500--text">{{$t('generals.RUT')}} del conductor</v-list-item-title>
                              <v-list-item-subtitle v-if="$store.getters['base/currentStates']">
                                <v-text-field v-model="$v.shipping.carrier_id.$model" :error="$v.shipping.carrier_id.$error" v-dni="$store.getters['base/currentCountry']" outlined single-line dense hide-details maxlength="16" />
                              </v-list-item-subtitle>
                            </v-list-item-content>
                          </v-list-item>
                        </v-col>
                        <v-col cols="2" class="pa-0">
                          <v-list-item class="pa-0">
                            <v-list-item-content>
                              <v-list-item-title class="grey-500--text">Patente del transporte</v-list-item-title>
                              <v-list-item-subtitle class="mt-1" v-if="$store.getters['base/currentStates']">
                                <v-text-field outlined single-line :error="$v.shipping.licence_plate.$error" class="pa-0" oninput="this.value=this.value.replace(/[^a-zA-Z0-9]/g,'');" dense v-model="$v.shipping.licence_plate.$model" @change="shipping.licence_plate = shipping.licence_plate.toUpperCase()" maxlength="7" hide-details placeholder="Ej        A B   •   C D   •   1 2" />
                              </v-list-item-subtitle>
                            </v-list-item-content>
                          </v-list-item>
                        </v-col>
                      </v-row>
                      <v-row class="mt-n2" no-gutters>
                        <v-col cols="5" class="pa-0">
                          <v-list-item class="pa-0">
                            <v-list-item-content>
                              <v-list-item-title class="grey-500--text">Dirección de envío</v-list-item-title>
                              <v-list-item-subtitle class="mt-1">
                                <v-text-field v-model="$v.shipping.address.$model" :error="$v.shipping.address.$error" oninput="this.value=this.value.replace(/[^a-zA-Z0-9,. ]/g,'');" outlined single-line maxlength="70" dense hide-details />
                              </v-list-item-subtitle>
                            </v-list-item-content>
                          </v-list-item>
                        </v-col>
                        <v-col cols="3" class="pa-0 ml-3" v-if="$store.getters['base/currentCountry'] === 'CL'">
                          <v-list-item class="pa-0">
                            <v-list-item-content>
                              <v-list-item-title class="grey-500--text">{{$t('generals.Comuna')}}</v-list-item-title>
                              <v-list-item-subtitle class="mt-1">
                                <v-autocomplete v-model="shipping.state" :items="$store.getters['base/currentStates']" item-value="name" item-text="name" :menu-props="{ overflowY: true, transition: 'slide-y-transition' }" height="40" single-line outlined dense hide-details placeholder="Selecciona la comuna" no-data-text="No existen datos" append-icon="mdi-chevron-down" />
                              </v-list-item-subtitle>
                            </v-list-item-content>
                          </v-list-item>
                        </v-col>
                      </v-row>
                    </v-list>
                  </v-col>
                </v-row>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
          <!-- end shipping inf -->

          <!-- detail -->
          <v-expansion-panels class="mt-5" v-model="panelDetail">
            <v-expansion-panel>
              <v-expansion-panel-header class="px-5 py-8">
                <v-row no-gutters align="center">
                  <v-col class="pa-0">
                    <span class="subtitle-2 grey-700--text font-weight-bold d-flex align-center"><v-icon class="mr-2" size="18" color="grey-700">mdi-basket</v-icon>Productos y/o servicios</span>
                  </v-col>
                </v-row>
              </v-expansion-panel-header>
              <v-expansion-panel-content class="px-0 pb-5">
                <v-row class="ma-0 mt-n2" no-gutters>
                  <v-col cols="12" class="pt-0 pl-5 pr-3">
                    <v-list class="transparent pa-0">
                      <div class="mb-n8 ma-0 pa-0" v-for="(d, i) in documents.lines" :key="`d-${i}`">
                        <v-divider class="mt-13 mb-5" v-if="i > 0" />
                        <v-row no-gutters>
                          <div class="px-0 py-8 mr-2" style="min-width:26px; margin-top:5px">
                            <v-chip class="px-2" id="product-index" color="blue-100" small label>{{ i + 1}}</v-chip>
                          </div>
                          <v-col class="pa-0">
                            <v-row no-gutters>
                              <v-col cols="2" class="pa-0 mx-2">
                                <v-list-item class="pa-0">
                                  <v-list-item-content>
                                    <v-list-item-title class="grey-500--text d-flex align-center">Tipo de código<v-chip class="ml-1 pa-2 caption grey-700--text" x-small color="grey-background">Opcional</v-chip></v-list-item-title>
                                    <v-list-item-subtitle class="mt-1">
                                      <v-text-field v-model="$v.documents.lines.$each[i].code_scheme.$model" :error="$v.documents.lines.$each[i].code_scheme.$error" oninput="this.value=this.value.replace(/[^a-zA-Z0-9,.-_ ]/g,'');" maxlength="10"  outlined single-line solo flat dense hide-details />
                                    </v-list-item-subtitle>
                                  </v-list-item-content>
                                </v-list-item>
                              </v-col>
                              <v-col cols="2" class="pa-0 mx-2">
                                <v-list-item class="pa-0">
                                  <v-list-item-content>
                                    <v-list-item-title class="grey-500--text d-flex align-center">Código<v-chip class="ml-1 pa-2 caption grey-700--text" x-small color="grey-background">Opcional</v-chip></v-list-item-title>
                                    <v-list-item-subtitle class="mt-1">
                                      <v-text-field v-model="$v.documents.lines.$each[i].code.$model" :error="$v.documents.lines.$each[i].code.$error" oninput="this.value=this.value.replace(/[^a-zA-Z0-9]/g,'');" maxlength="35" outlined single-line solo flat dense hide-details />
                                    </v-list-item-subtitle>
                                  </v-list-item-content>
                                </v-list-item>
                              </v-col>
                              <v-col cols="4" class="pa-0 mx-2">
                                <v-list-item class="pa-0">
                                  <v-list-item-content>
                                    <v-list-item-title class="grey-500--text">Nombre</v-list-item-title>
                                    <v-list-item-subtitle class="mt-1">
                                      <v-text-field v-model.trim="$v.documents.lines.$each[i].name.$model" :error="$v.documents.lines.$each[i].name.$error" required maxlength="80" outlined flat solo dense hide-details />
                                    </v-list-item-subtitle>
                                  </v-list-item-content>
                                </v-list-item>
                              </v-col>
                              <v-col class="pa-0 mx-2">
                                <v-list-item class="pa-0">
                                  <v-list-item-content>
                                    <v-list-item-title class="grey-500--text d-flex align-center">Descripción<v-chip class="ml-3 px-2" x-small color="grey-background">Opcional</v-chip></v-list-item-title>
                                    <v-list-item-subtitle class="mt-1">
                                      <v-text-field v-model.trim="$v.documents.lines.$each[i].description.$model" :error="$v.documents.lines.$each[i].description.$error" required maxlength="512" solo outlined flat dense hide-details />
                                    </v-list-item-subtitle>
                                  </v-list-item-content>
                                </v-list-item>
                              </v-col>
                            </v-row>
                          </v-col>
                        </v-row>
                        <v-row class="mt-n4" no-gutters>
                          <div class="d-flex mr-2" style="width: calc(17.5% + 26px) !important">
                            <v-col class="pa-0 mr-2">
                              <v-list-item class="pa-0">
                                <v-list-item-content>
                                  <v-list-item-title class="grey-500--text d-flex align-center">Unidad<v-chip class="ml-1 pa-2 caption grey-700--text" x-small color="grey-background">Opcional</v-chip></v-list-item-title>
                                  <v-list-item-subtitle class="mt-1">
                                    <v-select class="select-document" v-model="documents.lines[i].unit_label" :items="unitLabel" :menu-props="{ overflowY: true, transition: 'slide-y-transition' }" single-line outlined dense hide-details no-data-text="No existen unidaddes disponibles" placeholder="Selecciona" append-icon="mdi-chevron-down" />
                                  </v-list-item-subtitle>
                                </v-list-item-content>
                              </v-list-item>
                            </v-col>
                            <v-col class="pa-0 ml-2" style="min-width:70px !important;">
                              <v-list-item class="pa-0">
                                <v-list-item-content>
                                  <v-list-item-title class="grey-500--text">Cantidad</v-list-item-title>
                                  <v-list-item-subtitle class="mt-1">
                                    <v-text-field v-model.number="$v.documents.lines.$each[i].quantity.$model" :error="$v.documents.lines.$each[i].quantity.$error" oninput="this.value=this.value.replace(/[^0-9.]/g,'');" required maxlength="12" outlined flat solo dense hide-details />
                                  </v-list-item-subtitle>
                                </v-list-item-content>
                              </v-list-item>
                            </v-col>
                          </div>
                          <v-col cols="1" class="pa-0 mx-2" style="min-width:16.2% !important;">
                            <v-list-item class="pa-0">
                              <v-list-item-content>
                                <v-list-item-title class="grey-500--text">Precio</v-list-item-title>
                                <v-list-item-subtitle class="mt-1">
                                  <v-text-field v-model.number="$v.documents.lines.$each[i].amount.$model" :error="$v.documents.lines.$each[i].amount.$error" oninput="this.value=this.value.replace(/[^0-9.]/g,'');" required maxlength="18" single-line reverse outlined flat solo dense hide-details suffix="$" />
                                </v-list-item-subtitle>
                              </v-list-item-content>
                            </v-list-item>
                          </v-col>
                          <v-col cols="1" class="pa-0 mx-2" style="min-width:150px !important;">
                            <v-list-item class="pa-0">
                              <v-list-item-content>
                                <v-list-item-title class="grey-500--text d-flex align-center">Descuento<v-chip class="ml-1 pa-2 caption grey-700--text" x-small color="grey-background">Opcional</v-chip></v-list-item-title>
                                <v-list-item-subtitle class="mt-1">
                                  <v-text-field
                                    v-model="documents.lines[i].discount"
                                    oninput="this.value=this.value.replace(/[^0-9]/g,'');"
                                    height="26"
                                    outlined
                                    single-line
                                    solo
                                    flat
                                    dense
                                    hide-details
                                  >
                                    <template v-slot:prepend-inner>
                                      <div class="mr-0" style="width:35px">
                                        <v-select
                                          v-model="d.discount_scheme"
                                          :items="discountSchemes"
                                          class="select-discount"
                                          item-text="value"
                                          item-value="value"
                                          solo
                                          flat
                                          hide-details
                                          append-icon="mdi-chevron-down"
                                          :menu-props="{ overflowY: false, transition: 'slide-y-transition', bottom: true }"
                                        >
                                        </v-select>
                                      </div>
                                    </template>
                                  </v-text-field>
                                </v-list-item-subtitle>
                              </v-list-item-content>
                            </v-list-item>
                          </v-col>
                          <v-col :cols="$vuetify.breakpoint.width <= 1200 ? 2 : 3" class="pa-0 mx-2" v-if="currentIrsTax.length">
                            <v-list-item class="pa-0">
                              <v-list-item-content>
                                <v-list-item-title class="grey-500--text d-flex align-center">
                                  <span>{{nameIrsTax}}</span>
                                  <v-chip class="ml-1 pa-2 caption grey-700--text" v-if="documents.document_type !== 'CL46'" x-small color="grey-background">Opcional</v-chip></v-list-item-title>
                                <v-list-item-subtitle class="mt-1">
                                  <v-select class="select-document" v-model="documents.lines[i].irs_tax_code" :items="currentIrsTax" item-value="id" item-text="label" return-object :menu-props="{ overflowY: true, transition: 'slide-y-transition' }" single-line height="16" outlined dense hide-details no-data-text="No existen impuestos disponibles" placeholder="Selecciona el impuesto adicional" append-icon="mdi-chevron-down">
                                    <template v-slot:item="data">
                                      <v-list-item-title class="font-weight-regular">{{data.item.label}} {{data.item.value}}%</v-list-item-title>
                                    </template>
                                    <template v-slot:selection="data">
                                      <v-list-item-title class="font-weight-regular pl-2">{{data.item.label}} {{data.item.value}}%</v-list-item-title>
                                    </template>
                                  </v-select>
                                </v-list-item-subtitle>
                              </v-list-item-content>
                            </v-list-item>
                          </v-col>
                          <v-spacer />
                          <v-col class="pa-0 mx-2" style="min-width:200px !important;">
                            <div class="d-flex">
                            <v-col class="pa-0 mr-3">
                              <v-list-item class="pa-0">
                                <v-list-item-content>
                                  <v-list-item-title class="font-weight-bold text-right">Subtotal</v-list-item-title>
                                  <v-list-item-subtitle class="mt-2 font-weight-medium text-right">
                                    <template v-if="$v.documents.lines.$each[i].amount.$error || $v.documents.lines.$each[i].discount.$error || $v.documents.lines.$each[i].quantity.$error">-</template>
                                    <template v-else>
                                      <template v-if="aggregations.lines[i].totalItem">{{aggregations.lines[i].totalItem | currency(documents.document_type)}}</template>
                                      <template v-else>0</template>
                                      <template v-if="['CL110', 'CL111', 'CL112'].includes(documents.document_type)">{{exchange.currency_from}}</template>
                                      <template v-else>{{documents.document_type | firstDocumentsTypeCurrency}}</template>
                                    </template>
                                  </v-list-item-subtitle>
                                </v-list-item-content>
                              </v-list-item>
                            </v-col>
                            <div class="pa-0">
                              <v-list-item class="pa-0" style="width:43px !important;">
                                <v-list-item-content>
                                  <v-list-item-title class="grey-500--text">Exento</v-list-item-title>
                                  <v-list-item-subtitle class="ml-1">
                                    <v-switch class="ma-0" v-model="documents.lines[i].exempt" color="green-500" dense hide-details style="position: absolute; top: 36px" :ripple="false" />
                                  </v-list-item-subtitle>
                                </v-list-item-content>
                              </v-list-item>
                            </div>
                            <div class="px-0 py-8 text-right" v-if="i > 0" style="min-width: 35px;">
                              <v-btn @click="documents.lines.splice(i, 1)" :disabled="documents.lines.length === 1" min-width="20" max-width="20" min-height="28" max-height="28" text color="grey-200" :ripple="false"><v-icon size="24">mdi-delete</v-icon></v-btn>
                            </div>
                            </div>
                          </v-col>
                        </v-row>
                      </div>
                    </v-list>
                  </v-col>
                </v-row>
                <!-- add product -->
                <v-col class="px-5 mt-5 text-left">
                  <v-btn @click="addDocumentsLine" text color="blue-500" :ripple="false"><v-icon left>mdi-basket-plus</v-icon>
                    <span class="font-weight-medium body-1">Agregar producto</span>
                  </v-btn>
                </v-col>
                <!-- end add product -->
                <v-divider class="mt-2 mb-3" />
                <!-- summary -->
                <v-row align="center" justify="end">
                  <v-col cols="6" class="px-0 py-5">
                    <v-list class="v-list-form transparent pa-0">
                      <v-list-item class="py-0 px-8">
                        <v-list-item-title class="subtitle-2">Subtotal</v-list-item-title>
                        <v-list-item-subtitle class="text-right font-weight-bold grey-700--text">
                          <template v-if="linesWithAmountsError.length">-</template>
                          <template v-else>{{aggregations.subtotal | currency(documents.document_type)}}</template>
                        </v-list-item-subtitle>
                      </v-list-item>
                      <v-list-item class="py-0 px-8" v-if="!aggregations.irs_tax">
                        <v-list-item-title class="subtitle-2">Descuento</v-list-item-title>
                        <v-list-item-subtitle class="d-flex">
                          <v-spacer />
                          <v-col cols="5" class="text-right pa-0" style="min-width:150px !important;">
                            <v-text-field
                              v-model.number="documents.discount"
                              oninput="this.value=this.value.replace(/[^0-9]/g,'');"
                              height="26"
                              outlined
                              single-line
                              solo
                              flat
                              dense
                              hide-details
                            >
                              <template v-slot:prepend-inner>
                                <div class="mr-0" style="width:35px">
                                  <v-select
                                    v-model="documents.discount_scheme"
                                    :items="discountSchemes"
                                    class="select-discount"
                                    item-text="value"
                                    item-value="value"
                                    solo
                                    flat
                                    hide-details
                                    append-icon="mdi-chevron-down"
                                  >
                                  </v-select>
                                </div>
                              </template>
                            </v-text-field>
                          </v-col>
                          <span v-if="aggregations.discount && documents.discount_scheme === '%'">{{aggregations.discount | number}}</span>
                        </v-list-item-subtitle>
                      </v-list-item>
                      <v-list-item class="py-0 px-8 text-right" v-if="!$v.documents.discount.maxAmount || !$v.documents.discount.maxPercent">
                        <v-list-item-title class="red--text" v-if="!$v.documents.discount.maxAmount">El descuento no puede superar el subtotal</v-list-item-title>
                        <v-list-item-title class="red--text" v-if="!$v.documents.discount.maxPercent">El descuento no puede superar el 100%</v-list-item-title>
                      </v-list-item>
                      <template v-if="activeDocument.has_taxes_detail">
                        <v-divider class="mx-8 my-1" />
                        <v-list-item class="py-0 px-8">
                          <v-list-item-title class="subtitle-2">Exento</v-list-item-title>
                          <v-list-item-subtitle class="text-right font-weight-bold grey-700--text">
                            <template v-if="(linesWithAmountsError || []).length || (!$v.documents.discount.maxAmount || !$v.documents.discount.maxPercent)">-</template>
                            <template v-else>{{aggregations.exempt | currency(documents.document_type)}}</template>
                          </v-list-item-subtitle>
                        </v-list-item>
                        <template v-if="activeDocument.has_taxes">
                          <v-list-item class="py-0 px-8">
                            <v-list-item-title class="subtitle-2">Neto</v-list-item-title>
                            <v-list-item-subtitle class="text-right font-weight-bold grey-700--text">
                              <template v-if="(linesWithAmountsError || []).length || !$v.documents.discount.maxAmount || !$v.documents.discount.maxPercent">-</template>
                              <template v-else>{{aggregations.amount | currency(documents.document_type)}}</template>
                            </v-list-item-subtitle>
                          </v-list-item>
                          <template v-if="!exempt">
                            <v-list-item class="py-0 px-8" v-if="aggregations.vat_withheld">
                              <v-list-item-title class="subtitle-2">IVA retenido</v-list-item-title>
                              <v-list-item-subtitle class="text-right font-weight-bold grey-700--text">
                              <template v-if="(linesWithAmountsError || []).length || !$v.documents.discount.maxAmount || !$v.documents.discount.maxPercent">-</template>
                              <template v-else>{{aggregations.vat_withheld | currency(documents.document_type)}}</template>
                              </v-list-item-subtitle>
                            </v-list-item>
                            <v-list-item class="py-0 px-8">
                              <v-list-item-title class="subtitle-2">IVA ({{activeDocument.tax}}%)</v-list-item-title>
                                <v-list-item-subtitle class="text-right font-weight-bold grey-700--text">
                                <template v-if="(linesWithAmountsError || []).length || !$v.documents.discount.maxAmount || !$v.documents.discount.maxPercent">-</template>
                                <template v-else>{{aggregations.taxes | currency(documents.document_type)}}</template>
                              </v-list-item-subtitle>
                            </v-list-item>
                          </template>
                          <v-list-item class="py-0 px-8" v-if="currentIrsTax.length && !aggregations.vat_withheld">
                            <v-list-item-title class="subtitle-2">Impuestos</v-list-item-title>
                              <v-list-item-subtitle class="text-right font-weight-bold grey-700--text">
                              <template v-if="(linesWithAmountsError || []).length">-</template>
                              <template v-else>{{aggregations.irs_tax | currency(documents.document_type)}}</template>
                            </v-list-item-subtitle>
                          </v-list-item>
                        </template>
                      </template>
                      <v-divider class="mx-8 my-1" />
                      <v-list-item class="py-0 px-8">
                        <v-list-item-title class="subtitle-1 grey-700--text font-weight-bold">Total documento</v-list-item-title>
                        <v-list-item-subtitle class="text-right subtitle-1 grey-700--text font-weight-bold">
                          <template v-if="(linesWithAmountsError || []).length">-</template>
                          <template v-else>
                            {{aggregations.total | currency(documents.document_type)}}
                            <template v-if="['CL110', 'CL111', 'CL112'].includes(documents.document_type)">{{exchange.currency_from}}</template>
                            <template v-else>{{documents.document_type | firstDocumentsTypeCurrency}}</template>
                          </template>
                        </v-list-item-subtitle>
                      </v-list-item>
                    </v-list>
                  </v-col>
                </v-row>
                <!-- end summary -->
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
          <!-- end detail -->

          <!-- references inf -->
          <v-expansion-panels class="mt-5" v-model="panelReferences">
            <v-expansion-panel>
              <v-expansion-panel-header class="px-5 py-8">
                <v-row no-gutters align="center">
                  <v-col class="pa-0">
                    <span class="subtitle-2 grey-700--text font-weight-bold d-flex align-center"><v-icon class="mr-2" size="18" color="grey-700">mdi-clipboard-file</v-icon>Referencias<v-chip class="ml-3 pa-2 caption grey-700--text" x-small color="grey-background">Opcional</v-chip>
                    </span>
                  </v-col>
                </v-row>
              </v-expansion-panel-header>
              <v-expansion-panel-content class="px-7 pb-5 pt-0">
                <v-col cols="12" class="pa-0 mt-n2">
                  <div class="mb-n7" v-for="(d, i) in documents.references" :key="i" no-gutters>
                    <div class="d-flex justify-space-between ma-0 pa-0">
                      <div class="flex-grow-1">
                        <v-row align="center">
                          <div class="d-flex pa-0 mr-2" :style="`margin-top: 0px; min-width:${['CL61'].includes(documents.document_type) ? 330 : 250}px`">
                            <div class="pa-0 pt-8 mx-2" style="min-width:26px; margin-top:5px">
                              <v-chip class="px-2" id="product-index" color="blue-100" small label>{{ i + 1}}</v-chip>
                            </div>
                            <div class="pa-0 mx-2" v-if="['CL61'].includes(documents.document_type)">
                              <v-list-item class="pa-0" style="width:43px !important;">
                                <v-list-item-content class="py-2">
                                  <v-list-item-title class="grey-500--text">Global</v-list-item-title>
                                  <v-list-item-subtitle class="ml-1">
                                    <v-switch class="ma-0" v-model="documents.references[i].ind_global" @change="documents.references[i].serial = 0" color="green-500" dense hide-details style="position: absolute; top: 35px" :ripple="false" />
                                  </v-list-item-subtitle>
                                </v-list-item-content>
                              </v-list-item>
                            </div>
                            <div class="pa-0" style="width: 240px !important;">
                              <v-list-item class="pa-0">
                                <v-list-item-content class="py-2">
                                  <v-list-item-title class="grey-500--text">Tipo de documento</v-list-item-title>
                                  <v-list-item-subtitle class="mt-1">
                                    <v-autocomplete
                                      v-model="$v.documents.references.$each[i].reference_type.$model"
                                      :error="$v.documents.references.$each[i].reference_type.$error"
                                      :items="backupReferencesList"
                                      @focus="documentTypeRef(i)"
                                      @input="currentDocumentTypeRef = documents.references[i].reference_type"
                                      @change="documents.references[i].serial = null"
                                      item-value="id"
                                      item-text="name"
                                      item-disabled="customDisabled"
                                      :menu-props="{ overflowY: true, transition: 'slide-y-transition', maxWidth: '500px' }"
                                      height="40"
                                      single-line
                                      outlined
                                      dense
                                      hide-details
                                      append-icon="mdi-chevron-down"
                                      placeholder="Selecciona un tipo de documento"
                                    >
                                    <template v-slot:selection="data">
                                      <v-list-item-title class="grey-500--text" style="min-width: 93% !important">{{data.item.name}}</v-list-item-title>
                                    </template>
                                    </v-autocomplete>
                                  </v-list-item-subtitle>
                                </v-list-item-content>
                              </v-list-item>
                            </div>
                          </div>
                          <v-col class="pa-0 mx-2">
                            <v-list-item class="pa-0">
                              <v-list-item-content class="py-2">
                                <v-list-item-title :class="['grey-500--text', { 'disabled-item': documents.references[i].ind_global }]">N° de documento</v-list-item-title>
                                <v-list-item-subtitle class="mt-1">
                                  <v-text-field v-if="['CL801', 'CL802', 'CL803', 'CL804', 'CL805', 'CL806', 'CL807', 'CL808', 'CL809', 'CL810', 'CL811', 'CL812', 'CL813', 'CL814', 'CL815'].includes(currentDocumentTypeRef)" v-model.trim="$v.documents.references.$each[i].serial.$model" :error="$v.documents.references.$each[i].serial.$error" oninput="this.value=this.value.replace(/[^a-zA-Z0-9-_,. ]/g,'');" maxlength="18" height="36" solo outlined flat dense hide-details :disabled="documents.references[i].ind_global" />
                                  <v-text-field v-else v-model.trim="$v.documents.references.$each[i].serial.$model" :error="$v.documents.references.$each[i].serial.$error" oninput="this.value=this.value.replace(/[^a-zA-Z0-9]/g,'');" maxlength="18" height="36" solo outlined flat dense hide-details :disabled="documents.references[i].ind_global" />
                                </v-list-item-subtitle>
                              </v-list-item-content>
                            </v-list-item>
                          </v-col>
                          <v-col cols="2" class="pa-0 mx-2">
                            <v-list-item class="pa-0">
                              <v-list-item-content class="py-2">
                                <v-list-item-title class="grey-500--text">Fecha</v-list-item-title>
                                <v-list-item-subtitle class="mt-1">
                                  <v-menu v-model="datePickerMenuDocumentsReferences[i]" :close-on-content-click="false" top offset-y transition="slide-y-transition" max-width="290">
                                    <template v-slot:activator="{ on, attrs }">
                                      <v-text-field v-model="$v.documents.references.$each[i].date.$model" :error="$v.documents.references.$each[i].date.$error" v-bind="attrs" v-on="on" append-icon="mdi-calendar-blank" placeholder="dd-mm-aaaa" readonly height="36" solo outlined flat dense hide-details />
                                    </template>
                                    <v-date-picker v-model="$v.documents.references.$each[i].date.$model" @input="datePickerMenuDocumentsReferences[i]=false" no-title :first-day-of-week="1" type="date" locale="es" width="290" color="blue-500">
                                      <v-row class="pb-5 px-6" align="center" justify="space-between">
                                        <v-btn class="body-1" :ripple="false" text color="blue-500" @click="() => { $v.documents.references.$each[i].date.$model=''; datePickerMenuDocumentsReferences[i]=false }">Limpiar</v-btn>
                                        <v-spacer />
                                        <v-btn @click="() => { $v.documents.references.$each[i].date.$model=today; datePickerMenuDocumentsReferences[i]=false }" color="blue-500">Hoy</v-btn>
                                      </v-row>
                                    </v-date-picker>
                                  </v-menu>
                                </v-list-item-subtitle>
                              </v-list-item-content>
                            </v-list-item>
                          </v-col>
                          <v-col
                            :cols="!documentTypeForOverrideType[documents.document_type]?.includes(documents.references[i]?.reference_type) ? 2 : '2'"
                            class="pa-0 mx-2"
                            v-if="documentTypeForOverrideType[documents.document_type]?.includes(documents.references[i]?.reference_type)"
                          >
                            <v-list-item class="pa-0">
                              <v-list-item-content class="py-2">
                                <v-list-item-title class="grey-500--text">Tipo de anulación</v-list-item-title>
                                <v-list-item-subtitle class="mt-1">
                                    <v-autocomplete v-model="$v.documents.references.$each[i].cod_ref.$model" :error="$v.documents.references.$each[i].cod_ref.$error" :items="overrideType" item-value="id" item-text="name" :menu-props="{ overflowY: true, transition: 'slide-y-transition' }" height="36" solo outlined flat dense hide-details append-icon="mdi-chevron-down"
                                  >
                                    <template v-slot:selection="data">
                                      <v-list-item-title class="grey-500--text" style="min-width: 93% !important">{{data.item.name}}</v-list-item-title>
                                    </template>
                                  </v-autocomplete>
                                </v-list-item-subtitle>
                              </v-list-item-content>
                            </v-list-item>
                          </v-col>
                          <v-col
                            :cols="!documentTypeForOverrideType[documents.document_type]?.includes(documents.references[i]?.reference_type) ? '' : '4'"
                            class="pa-0 mx-2"
                            :style="`${['CL61'].includes(documents.document_type) ? 'min-width:330px; max-width:330px;' : ''}`"
                          >
                            <v-list-item class="pa-0">
                              <v-list-item-content class="py-2">
                                <v-list-item-title class="grey-500--text">Descripción</v-list-item-title>
                                <v-list-item-subtitle class="mt-1">
                                  <v-text-field v-model.trim="$v.documents.references.$each[i].description.$model" :error="$v.documents.references.$each[i].description.$error" oninput="this.value=this.value.replace(/[^a-zA-Z0-9-_,. ]/g,'');" maxlength="36" height="36" solo outlined flat dense hide-details />
                                </v-list-item-subtitle>
                              </v-list-item-content>
                            </v-list-item>
                          </v-col>
                        </v-row>
                      </div>
                      <div
                        v-if="i > 0"
                        class="text-right ml-4 mt-6"
                        style="max-width: 40px;"
                      >
                        <v-btn @click="documents.references.splice(i, 1)" min-width="20" max-width="20" min-height="28" max-height="28" text :ripple="false" color="grey-200"><v-icon size="24">mdi-delete</v-icon></v-btn>
                      </div>
                    </div>
                    <v-divider :class="`${i === 0 ? 'mt-5' : 'mt-5'} mb-12`" v-if="i !== ((documents || {}).references || []).length - 1" />
                  </div>
                  <div :class="((documents || {}).references || []).length === 1 ? 'mt-10' : 'mt-8'">
                    <v-btn class="font-weight-bold" text color="blue-500" :ripple="false" @click="addDocumentsReference"><v-icon left>mdi-file-plus-outline</v-icon>
                      <span class="font-weight-medium body-1">Agregar referencia</span>
                    </v-btn>
                  </div>
                </v-col>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
          <!-- end references inf -->

          <v-card class="mt-5" flat>
            <v-card-actions class="py-4 px-5">
              <v-spacer />
              <v-btn :to="{ name: 'DocumentsList' }" outlined>Cancelar</v-btn>
              <v-btn color="blue-500" dark @click="handlePreviewPDF">Continuar</v-btn>
            </v-card-actions>
          </v-card>
        </v-col>
      </v-col>
    </template>
    <!-- dialog preview PDF -->
    <v-dialog v-model="dialogReviewPDF" width="990" persistent no-click-animation scrollable overlay-color="overlay">
      <v-card>
        <template>
          <v-toolbar flat height="46" class="transparent">
            <v-toolbar-title class="px-5 subtitle-2 font-weight-semibold grey-500--text">Vista previa del documento</v-toolbar-title>
            <v-spacer />
            <v-toolbar-items>
              <v-row align="center" justify="center" class="mr-3">
                <v-btn @click="dialogReviewPDF=false" icon small retain-focus-on-click><v-icon color="grey-300--text" size="20">mdi-close</v-icon></v-btn>
              </v-row>
            </v-toolbar-items>
          </v-toolbar>
          <v-divider />
        </template>
        <v-card-text class="pa-0" style="max-height: 600px; overflow: hidden;">
          <v-row no-gutters>
            <v-col cols="4" class="pa-0">
              <v-card class="pa-0" flat>
                <v-toolbar color="transparent" flat height="44">
                  <v-toolbar-title class="px-5 subtitle-2 grey-500--text font-weight-semibold">Resumen del documento</v-toolbar-title>
                </v-toolbar>
                <v-card-text class="pa-0" outlined>
                <!-- header -->
                  <v-card-text class="py-0 pl-3 pr-1 grey-700--text rounded-b-0">
                    <v-img contain :width="316" :height="110" :src="require(`@/assets/backgrounds/background-document.svg`)">
                      <span class="px-4 pt-4 subtitle-2 white--text font-weight-bold d-inline-block text-truncate" style="max-width: 80%">
                        {{((documents || {}).document_type || '') | nameDocumentsType}} Nº{{number}}
                      </span>
                      <v-row class="ml-2 mt-3" align="end" no-gutters>
                        <v-col cols="5" class="py-0 px-2">
                          <span class="d-block body-1 white--text">Total</span>
                          <span class="subtitle-2 white--text">
                            <span class="font-weight-bold">{{(documents.total || aggregations.total) | currency((documents || {}).document_type || '')}}</span>
                            <span class="caption">
                              <template v-if="Object.keys(((documents || {}).exchange || {})).length">{{documents.exchange.currency_from || documents.exchange.currency_to || documents.exchange.currency}}</template>
                              <template v-else>{{((documents || {}).document_type || '') | firstDocumentsTypeCurrency}}</template>
                            </span>
                          </span>
                        </v-col>
                        <v-col cols="6" class="py-0">
                          <span class="d-block body-1 white--text">Fecha</span>
                          <span class="subtitle-2 white--text font-weight-bold">{{documents.date | date}}</span>
                        </v-col>
                      </v-row>
                    </v-img>
                  </v-card-text>
                  <!-- end header -->
                  <v-divider class="mt-4" />
                  <!-- detail -->
                  <div class="pa-5">
                    <v-list class="v-list-form-main transparent pa-0">
                      <v-list-item class="pa-0">
                        <v-list-item-title class="grey-700--text subtitle-2">Razón Social</v-list-item-title>
                        <v-list-item-subtitle class="body-1">{{((documents || {}).customer || {}).name || ''}}</v-list-item-subtitle>
                      </v-list-item>
                      <v-list-item class="pa-0">
                        <v-list-item-title class="grey-700--text subtitle-2">{{$t('generals.RUT')}}</v-list-item-title>
                        <v-list-item-subtitle class="body-1">{{((documents || {}).customer || {}).taxpayer || ''}}</v-list-item-subtitle>
                      </v-list-item>
                      <v-list-item class="pa-0">
                        <v-list-item-title class="grey-700--text subtitle-2">Tipo de doc.</v-list-item-title>
                        <v-list-item-subtitle class="body-1">{{((documents || {}).document_type || '') | nameDocumentsType}}</v-list-item-subtitle>
                      </v-list-item>
                      <v-list-item class="pa-0" v-if="documents.number">
                        <v-list-item-title class="grey-700--text subtitle-2">{{$t('generals.Folio')}}</v-list-item-title>
                        <v-list-item-subtitle class="body-1">{{number}}</v-list-item-subtitle>
                      </v-list-item>
                    </v-list>
                  </div>
                  <!-- end detail -->
                </v-card-text>
              </v-card>
            </v-col>
            <v-divider class="ma-0" vertical />
            <v-col>
              <v-alert class="inf-alert body-2 'blue-500--text" v-show="!loaderRender && renderUrlPDF" :height="44" dense text outlined color="blue-500" tile>Esta visualización es una vista previa del documento, por lo que no es válido legalmente.</v-alert>
              <v-row :class="{'mt-n3' : !loaderRender}" no-gutters align="center" justify="center">
                <v-col cols="12" class="py-0 px-4 ma-0" v-if="loaderRender">
                  <v-skeleton-loader class="rounded-0 pa-0" type="table" />
                </v-col>
                <v-empty-state v-else-if="!renderUrlPDF" class="mt-16 pt-16" title="PDF no disponible" :height="100" type="pdf" customClass="mt-6" :isFree="true" />
              </v-row>
              <div v-show="!loaderRender && renderUrlPDF" id="render"></div>
            </v-col>
          </v-row>
        </v-card-text>
        <v-divider />
        <v-card-actions class="py-0 px-5"> <!--py-2  -->
          <v-col cols="4" class="pa-0" style="margin-right: -7px;" />
          <v-divider class="ma-0" vertical style="height:50px !important" />
          <v-spacer />
          <v-btn @click="dialogReviewPDF=false" outlined>Volver</v-btn>
          <v-btn @click="createDocuments('create')" :loading="loadingSave || loadingFinalize" color="blue-500">Crear documento</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- end dialog preview PDF -->

    <!--modal update customer-->
    <v-dialog v-model="dialogCustomer" width="700" persistent no-click-animation overlay-color="grey-500" scrollable :key="resetDialogCustomer">
      <CustomerEdit
        :dialogCustomer.sync="dialogCustomer"
        :customer="documents.customer"
        @update="getTaxpayer"
      />
    </v-dialog>
    <!-- modal update customer -->
  </v-row>
</template>
<script>
import { mapState } from 'vuex'
import PermissionDenied from '@/modules/http/views/PermissionDenied'
import { decimal, maxLength, minValue, numeric, required, email, requiredIf } from 'vuelidate/lib/validators'
import GenericViewMixin from '@/mixins/GenericViewMixin'
import { rutValidator } from 'vue-dni'
import MiniHeader from '@/components/commons/MiniHeader'
import VEmptyState from '@/components/commons/VEmptyState'
import DocumentTypes from '@/collections/documentTypes'
// import RenderViewMixin from '@/mixins/RenderViewMixin'
import SimplifiedCountryList from '@/collections/simplifiedCountryList'
import ReferencesList from '@/collections/referencesList'
import TaxAgencyViewMixin from '@/mixins/TaxAgencyViewMixin'
import cloneDeep from 'lodash/cloneDeep'
import moment from 'moment'
import CustomerEdit from '@/modules/customer/components/CustomerEdit'
import SkeletonLoaderDocumentCreate from '@/modules/documents/components/SkeletonLoaderDocumentCreate'

export default {
  name: 'documents-create',
  mixins: [
    GenericViewMixin,
    // RenderViewMixin,
    TaxAgencyViewMixin
  ],
  components: {
    PermissionDenied,
    VEmptyState,
    MiniHeader,
    SkeletonLoaderDocumentCreate,
    CustomerEdit
  },
  data: () => ({
    panelInf: 0,
    panelReceiver: 0,
    panelExport: 0,
    panelShipping: 0,
    panelDetail: 0,
    panelReferences: 0,
    resetDialogCustomer: 0,
    dialogCustomer: false,
    customerLoading: false,
    customerAditionals: {
      contacts: [],
      extra_addresses: [],
      business_activities: [],
      isCustomer: false
    },
    isCreate: false,
    scroll: 0,
    hiddenAction: true,
    documentParent: '',
    loadingDetail: false,
    renderUrlPDF: null,
    loaderRender: false,
    dialogReviewPDF: false,
    exempt: false,
    attrs: {
      boilerplate: true,
      elevation: 0
    },
    customerNotExists: false,
    activeCustomer: {},
    aggregations: {
      lines: [
        {
          subtotal: 0
        }
      ],
      amount: 0,
      exempt: 0,
      subtotal: 0,
      discount: 0,
      taxes: 0,
      total: 0,
      irs_tax: 0,
      vat_withheld: 0
    },
    datePickerMenuDocumentsDueDate: false,
    datePickerMenuDocumentsDate: false,
    datePickerMenuDocumentsReferences: [false],
    datePickerMenuDocumentsPeriodStart: false,
    datePickerMenuDocumentsPeriodEnd: false,
    discountSchemes: [
      { name: 'Porcentaje', value: '%' },
      { name: 'Monto', value: '$'}
    ],
    documents: {
      customer: {
        name: '',
        taxpayer: '',
        country: null,
        state: '',
        address: '',
        phone: null,
        email: '',
        business_activity: '',
        contacts: []
      },
      document_type: '',
      // currency: null,
      document: null,
      number: null,
      collection_method: null,
      date: '',
      due_date: null,
      discount: null,
      discount_scheme: '%',
      period_start: null,
      period_end: null,
      lines: [
        {
          name: '',
          code: '',
          code_scheme: '',
          exempt: false,
          unit_label: null,
          amount: null,
          quantity: null,
          discount: null,
          discount_scheme: '%',
          tax: null,
          tax_percent: null,
          irs_tax_code: null
        }
      ],
      references: [{
        date: '',
        description: '',
        serial: null,
        cod_ref: null,
        reference_type: '',
        ind_global: false
      }],
      issuer: {
        branch: ''
      }
    },
    shipping: {
      address: '',
      state: '',
      carrier_id: '',
      carrier_name: '',
      licence_plate: '',
      type: ''
    },
    exchange: {
      currency_from: null,
      rate: null
    },
    loadingSave: false,
    loadingFinalize: false,
    menusLineCode: [],
    menusLineDiscount: [],
    paymentDue: 30,
    sourcesDetail: {},
    documentTypes: DocumentTypes,
    referencesList: ReferencesList,
    simplifiedCountryList: SimplifiedCountryList,
    currentCountry: null,
    shippingTypes: [
      { value: 1, label: 'Operación constituye venta' },
      { value: 2, label: 'Ventas por efectuar' },
      { value: 3, label: 'Consignaciones' },
      { value: 4, label: 'Entrega gratuita' },
      { value: 5, label: 'Traslados internos' },
      { value: 6, label: 'Otros traslados no venta' },
      { value: 7, label: 'Guía de devolución' },
      { value: 8, label: 'Traslado para exportación. (no venta)' },
      { value: 9, label: 'Venta para exportación' }
    ],
    irsTaxCodeTypes: [
      { id: '15', label: 'IVA retenido', value: 19, document_type: ['CL46', 'CL61', 'CL56'] },
      // { id: '17', label: 'IVA anticipado faenamiento carne', value: 5 },
      // { id: '18', label: 'IVA anticiado carne', value: 5 },
      // { id: '19', label: 'IVA anticipado harina', value: 12 },
      { id: '23', label: 'Artículos: oro, platino, marfil; joyas; piedras preciosas, pieles finas', value: 15, document_type: ['CL30', 'CL33', 'CL60', 'CL61', 'CL55', 'CL56'] },
      { id: '24', label: 'Licores, Piscos, Whisky', value: 31.5, document_type: ['CL30', 'CL33', 'CL60', 'CL61', 'CL55', 'CL56'] },
      { id: '25', label: 'Vinos', value: 20.5, document_type: ['CL30', 'CL33', 'CL60', 'CL61', 'CL55', 'CL56'] },
      { id: '26', label: 'Cervezas y Bebidas Alcoh.', value: 20.5, document_type: ['CL30', 'CL33', 'CL60', 'CL61', 'CL55', 'CL56'] },
      { id: '27', label: 'Bebida Analcoh. y Mineral', value: 10, document_type: ['CL30', 'CL33', 'CL60', 'CL61', 'CL55', 'CL56'] },
      { id: '271', label: 'Bebidas Azucaradas', value: 18, document_type: ['CL30', 'CL33', 'CL60', 'CL61', 'CL55', 'CL56'] },
      { id: '30', label: 'IVA retenido legumbres', value: 10, document_type: ['CL46', 'CL61', 'CL56'] },
      { id: '31', label: 'IVA retenido silvestres', value: 19, document_type: ['CL46', 'CL61', 'CL56'] },
      { id: '32', label: 'IVA retenido ganado', value: 8, document_type: ['CL46', 'CL45', 'CL60', 'CL61', 'CL55', 'CL56'] },
      { id: '33', label: 'IVA retenido madera', value: 8, document_type: ['CL46', 'CL61', 'CL56'] },
      { id: '34', label: 'IVA retenido trigo', value: 4, document_type: ['CL46', 'CL61', 'CL56'] },
      { id: '36', label: 'IVA retenido arroz', value: 10, document_type: ['CL46', 'CL61', 'CL56'] },
      { id: '37', label: 'IVA retenido hidrobiológicas', value: 10, document_type: ['CL46', 'CL61', 'CL56'] },
      { id: '38', label: 'IVA retenido chatarra', value: 19, document_type: ['CL46', 'CL61', 'CL56'] },
      { id: '39', label: 'IVA retenido PPA', value: 19, document_type: ['CL46', 'CL61', 'CL56'] },
      { id: '41', label: 'IVA retenido construcción', value: 19, document_type: ['CL46', 'CL61', 'CL56'] },
      // { id: '44', label: 'Art 37 letras E, H, I, L', value: 15 },
      // { id: '45', label: 'Art 37 letra J', value: 50 },
      { id: '47', label: 'IVA retenido cartones', value: 19, document_type: ['CL46', 'CL61', 'CL56'] },
      { id: '48', label: 'IVA retenido frambuesas y pasas', value: 14, document_type: ['CL46', 'CL61', 'CL56'] },
      { id: '49', label: 'Factura de compra sin retención', value: 0, document_type: ['CL46', 'CL61', 'CL56'] }
    ],
    overrideType: [
      {
        id: 1,
        name: 'Anula documento de referencia'
      },
      {
        id: 2,
        name: 'Corrige texto documento de referencia'
      },
      {
        id: 3,
        name: 'Corrige monto documento de referencia'
      }
    ],
    documentTypeForOverrideType: {
      CL61: ['CL33', 'CL34', 'CL39', 'CL41', 'CL46', 'CL30', 'CL32', 'CL35', 'CL38', 'CL45', 'CL55', 'CL55'],
      CL56: ['CL33', 'CL34', 'CL39', 'CL41', 'CL46', 'CL56', 'CL30', 'CL32', 'CL35', 'CL38', 'CL45', 'CL55', 'CL60'],
      CL111: ['CL112', 'CL110'],
      CL112: ['CL110', 'CL111']
    },
    unitLabel: ['TON', 'KG', 'UNID', 'QTAL', 'M3', 'MR', 'TBDM', 'TBDU', 'PP', 'PM', 'LTS', 'M2', 'PAQ', 'LBR', 'CPL', 'HRS', 'M', 'TRS', 'KM', 'PIES', 'EA'],
    currentDocumentTypeRef: '',
    backupReferencesList: [],
    breadcrumbs: {
      main: 'Emisión',
      children: [
        {
          text: 'Documentos',
          route: 'DocumentsList'
        },
        {
          text1: 'Crear documento'
        }
      ]
    }
  }),
  computed: {
    ...mapState({
      countriesList: state => state.base.countriesList,
      branchList: state => state.branches.branchesList
    }),
    nameIrsTax () {
      return this.$vuetify.breakpoint.width <= 1200 ? 'Imp. adicional' : 'Impuestos adicionales'
    },
    checkPermissionDTE () {
      if (!this.$store.state.accounts.me?.is_owner && !this.$store.state.auth.userAccount.is_staff) {
        /* eslint-disable */
        const ownGroupPermissions = [...(this.$store.state.accounts.me?.document_type_permissions ?? []), ...(this.$store.state.accounts.me?.groups?.map(({document_type_permissions}) => document_type_permissions)[0] ?? [])]
        return !ownGroupPermissions.filter(({permission}) => permission === 'add_document').length
      }
      return false
    },
    activeDocument () {
      return this.documentTypes.find(d => d.id === this.documents.document_type) || {}
    },
    currentDocumentList () {
      const list = this.documentTypes?.filter(d => (d.country === this.$store.state.auth.account.country)) ?? []
      const filtered = this.$store?.state?.auth?.account?.country !== 'PE' ? list.filter(({id}) => !['CL43'].includes(id)) : list

      if (!this.$store.state.accounts.me?.is_owner && !this.$store.state.auth.userAccount.is_staff) {
        /* eslint-disable */
        const ownGroupPermissions = [...(this.$store.state.accounts.me?.document_type_permissions ?? []), ...(this.$store.state.accounts.me?.groups?.map(({document_type_permissions}) => document_type_permissions)[0] ?? [])]

        return filtered?.filter(({id}) => ownGroupPermissions?.filter(({permission}) => permission === 'add_document').some(({document_type}) => document_type === id))
      }
      return filtered
    },
    currentIrsTax () {
     /* eslint-disable */
      return this.irsTaxCodeTypes?.filter(({document_type}) => document_type?.some((d) => d === this.documents.document_type))
    },
    linesWithAmountsError () {
      const lines = this.$v.documents.lines
      return this.documents.lines.filter((l, i) => lines.$each[i].amount.$error || lines.$each[i].discount.$error || lines.$each[i].quantity.$error)
    },
    aggregationsSource () {
      return {
        discount_scheme: this.documents.discount_scheme,
        discount: this.documents.discount,
        lines: this.documents.lines.map(line => {
          return {
            amount: line.amount,
            discount: line.discount,
            discount_scheme: line.discount_scheme,
            exempt: line.exempt,
            quantity: line.quantity,
            irs_tax: line.irs_tax_code ?? 0
          }
        })
      }
    },
    number () {
      return this.$store.state?.auth?.account?.country === 'PE' ? `${this.documents.prefix} - ${this.documents.number}` : this.documents.number
    }
    // handledErrors () {
    //   return this.baseHandledErrors.concat([
    //     {
    //       action: 'Cargar folios',
    //       message: `La cuenta no tiene un folio válido para ${this.activeDocument.name}.`,
    //       route: { name: 'IrsBuiltIn', query: { ref: 'folios' } },
    //       snippet: 'not have a valid folio'
    //     }
    //   ])
    // }
  },
  created () {
    if (this.$helpers.hasPermission(['view_branch'])) {
      this.$store.dispatch('branches/LIST', {
        resource: 'branches',
        query: { page_size: 9999}
      })
    }
    if (this.$route.name === 'DocumentsClone') {
      this.isCreate = true
      this.loadingDetail = true
      this.$store.dispatch('documents/GET', {
        resource: `documents/${this.$route.params.id}/clone/`
      })
      .then((response) => {
        const result = cloneDeep(response.data)

        const items = response.data.clone.items.map((item) => {
          return {
            ...item,
            ...(!item.discount && !item.discount_scheme && { discount: 0, discount_scheme: '%' } )
          }
        })
        let issuer = {
          branch: ''
        }
        this.documents = { ...{ references: response.data.clone.references }, ...{ lines: items }, ...result, ...{ issuer } }
        this.documents.lines.forEach((item) => {
          item.irs_tax_code = item?.irs_tax_code?.toString()
        })

        this.documents.document_type = this.$route.query.document_type
        this.documentParent = result.document_type

         this.customerAditionals = {
          contacts: ![null, ''].includes(this.documents?.customer?.contacts[0]) ? this.documents.customer?.contacts : [],
          extra_addresses: this.documents.customer?.extra_addresses ?? [],
          business_activities: this.documents.customer?.business_activities ?? [],
          isCustomer: true
        }

        if (this.documents.customer) {
          this.documents.customer = {
            name: this.documents.customer.name,
            taxpayer: this.documents.customer.taxpayer.tax_id,
            country: this.documents.customer?.taxpayer?.country ?? this.$store.state.auth.account.country,
            state: this.documents.customer.state,
            address: this.documents.customer.address,
            phone: this.documents.customer.phone,
            email: this.documents.customer.email,
            business_activity: this.documents.customer.business_activity?.length > 40 ? this.documents.customer.business_activity?.slice(0, 40) : this.documents.customer.business_activity
          }
        }
        else {
          this.documents.customer = {
            name: this.documents.taxpayer.name,
            taxpayer: this.documents.taxpayer.tax_id,
            country: this.documents.taxpayer?.taxpayer?.country ?? this.$store.state.auth.account.country,
            state: this.documents.taxpayer.state,
            address: this.documents.taxpayer.address,
            phone: this.documents.taxpayer.phone,
            email: this.documents.taxpayer.email,
            business_activity: this.documents?.taxpayer?.irs_settings?.cl_activity_description?.length > 40 ? this.documents?.taxpayer?.irs_settings?.cl_activity_description?.slice(0, 40) : this.documents?.taxpayer?.irs_settings?.cl_activity_description
          }
        }

        if (this.documents.exchange) {
          this.exchange = this.documents.exchange
        }
        
        this.setAggregations()
        this.documents.date = null
        this.documents.due_date = null

        if (this.documents.document_type !== 'CL52') delete this.documents?.shipping

        if (['CL56', 'CL61', 'CL111', 'CL112'].includes(this.$route.query.document_type) && this.$route.query.action) {
          this.documents.references = [{
            date: response.data.clone.date,
            description: this.overrideType?.find((item) => item.id.toString() === this.$route.query.action)?.name,
            serial: this.documents.number,
            cod_ref: parseInt(this.$route.query.action),
            reference_type: this.documentParent
          }]
          this.documentTypeRef(0)

          // los montos de la nota deben estar en cero a pesar que el documento original tenga valores diferentes de cero  jira - FRNT-42
          if (this.$route.query.action === '2') {
            this.documents.lines.forEach((item) => {
              item.amount = 0
              item.discount = 0
              item.irs_tax_code = null
              item.total = 0
            })
            this.documents.amount = 0
            this.documents.discount = 0
            this.documents.subtotal = 0
            this.documents.total = 0
            this.documents.tax_percent = 0
            this.documents.tax = 0
          }
        } else {
          this.documents.references = [{
            date: '',
            description: '',
            serial: null,
            cod_ref: null,
            reference_type: ''
          }]
        }

        if (this.$route.query.action === 'invoice-guide') {
          this.documents.discount = response.data.clone.discount
          this.documents.discount_scheme = response.data.clone.discount_scheme
        }

        if (this.documents.document_type === 'CL46') {
          this.documents.lines.forEach((item) => {
            item.irs_tax_code = { id: '15', label: 'IVA retenido', value: 19, document_type: ['CL46'] }
          })
        }

        this.$nextTick(() => {
          if (['CL33'].includes(this.$route.query.document_type) && Object.keys(this.$route.query)?.length === 1) {
            this.documents.references = [{
              date: response.data.clone.date,
              description: 'Facturar guía',
              serial: this.documents.number,
              reference_type: this.documentParent
            }]
            this.documentTypeRef(0)
          }
          this.documents.lines.forEach((item) => {
          item.irs_tax_code = item?.irs_tax_code?.toString()
          if (['CL56', 'CL61'].includes(this.$route.query.document_type) && this.$route.query.origin === 'CL34') {
            item.exempt = true
          }
          })

          if (this.shipping.type) this.shipping.type = parseInt(this.documents.shipping.type)
          if (this.$store?.state?.auth?.account?.allowed_to_assign) this.documents.number = null
        })
      })
      .finally(() => {
        this.loadingDetail = false
        this.isCreate = false
      })

    }

    this.$loadScript('/static/js/pdfobject.min.js')
  },
  watch: {
    dialogCustomer (val) {
      if (!val) this.resetDialogCustomer += 1
    },
    'documents.date' (val) {
      if (val) this.documents.due_date = moment(val).add(30, 'days').format('YYYY-MM-DD')
    },
    'documents.document_type' (newVal, oldVal) {
      // const ref = [{
      //   date: '',
      //   description: '',
      //   serial: null,
      //   cod_ref: null,
      //   reference_type: ''
      // }]
      this.documents.collection_method  = ['CL46', 'CL33', 'CL34', 'CL43'].includes(newVal) ? 2 : null
      this.documents.lines[0].irs_tax_code = newVal === 'CL46' ? { id: '15', label: 'IVA retenido', value: 19, document_type: ['CL46'] } : this.documents.lines[0]?.irs_tax_code

      if (newVal === 'CL52') {
        this.documents.lines?.forEach((item) => {
          item.irs_tax_code = null
        })
      }

      if (['CL56', 'CL61', 'CL111', 'CL112'].includes(newVal) || ('CL33' === newVal && this.$route.query.action === 'invoice-guide')) {
        if ((newVal !== oldVal && oldVal !== '' || !this.$route.query.action)) {
          // this.documents.references = ref
          this.hiddenAction = false
        }
      } else {
        // this.documents.references = []
        this.hiddenAction = false
      }
      this.$v.documents.references.$reset()
      this.$v.documents.customer.$reset()
      this.exempt = false
      this.documents.date = this.today
      this.documents.due_date = moment(this.today).add(30, 'days').format('YYYY-MM-DD')
    },
    'documents.customer.country' (val) {
      if (val && ['CL110', 'CL111', 'CL112'].includes(this.documents.document_type)) {
        this.documents.customer.state = ''
      }
    },
    'documents.customer.taxpayer' (val) {
      if (!this.isCreate) this.debounceMethod(this.getTaxpayer)
      if (!val?.length) {
        this.documents.customer = {
        taxpayer: '',
        name: '',
        country: null,
        state: '',
        address: '',
        phone: null,
        email: '',
        business_activity: ''
      }
        this.customerAditionals = {
          contacts: [],
          extra_addresses: [],
          business_activities: [],
          isCustomer: false
        }
        this.$v.documents.customer.$reset()
      }
    },
    activeDocument (newly, oldy) {
      const setExemptLines = (val) => {
        this.documents.lines.forEach((line, index) => {
          this.documents.lines[index].exempt = val
        })
      }
      if (this.activeDocument.has_taxes && !oldy.has_taxes) {
        setExemptLines(false)
      } else if (!this.activeDocument.has_taxes && oldy.has_taxes) {
        setExemptLines(true)
      }
      this.setAggregations()
    },
    aggregationsSource: {
      handler (val) {
        this.setAggregations()
      },
      deep: true,
      inmediate: true
    },
    '$store.state.base.retrieveLoader' (val) {
      if (!val) this.documents.customer.country = this.$store.getters['base/currentCountry']
    },
    exempt (val) {
      this.documents.lines = this.documents.lines.map((line) => {
        return {
          ...line,
          exempt: val
        }
      })
      this.setAggregations()
    }
  },
  methods: {
    onScroll (e) {
      this.scroll = e.target?.scrollingElement?.scrollTop ?? 0
    },
    handlePreviewPDF () {
      this.createDocuments()
      // this.renderDocument('documents', null, 'pdf', item.id)
      // this.renderDocument('batch', 'batch_documents', 'pdf', this.instance.random_preview[this.index])
    }, 
    documentTypeRef (i) {
      let aux = [...this.referencesList]
        aux.forEach(item => {
        if (!this.documentTypeForOverrideType[this.documents.document_type]?.length) item.customDisabled = false
        else {
          if (i === 0) {
            item.customDisabled = !this.documentTypeForOverrideType[this.documents.document_type].includes(item.id)
          } else item.customDisabled = false
        }
      })
      this.backupReferencesList = aux
    },
    async getTaxpayer () {
      let error = false
      let respCustomers = []
      let respTaxpayer = null
      this.$v.documents.customer.taxpayer.$touch()

      if (this.$v.documents.customer.taxpayer.$invalid) {
        return false
      }

      this.customerLoading = true

      respCustomers = await this.$store.dispatch('base/LIST', {
        resource: 'customers',
        query: { taxpayer__tax_id: this.documents.customer.taxpayer.split('.').join('') } 
      })

      if (!respCustomers?.data?.results?.length) {
        try {
          respTaxpayer = await this.$store.dispatch('base/RETRIEVE', {
            resource: 'base/taxpayers',
            loader: false,
            id: this.documents.customer.taxpayer.split('.').join('')
          })
        } catch {
          error = true
        }
      }
      this.customerLoading = false 

      if ((respCustomers?.data?.results[0] || respCustomers?.data?.results[0])) {
        if (respCustomers?.data?.results[0]?.id) this.documents.customer.id = respCustomers?.data?.results[0]?.id
        this.documents.customer.name = respCustomers?.data?.results[0]?.name ?? respTaxpayer?.data?.results[0]?.name ?? ''
        this.documents.customer.contacts = respCustomers?.data?.results[0]?.contacts ?? respTaxpayer?.data?.results[0]?.contacts ?? []
        this.documents.customer.address = respCustomers?.data?.results[0]?.address ?? respTaxpayer?.data?.results[0]?.address
        this.documents.customer.phone = respCustomers?.data?.results[0]?.phone ?? respTaxpayer?.data?.results[0]?.phone
        this.documents.customer.email = respCustomers?.data?.results[0]?.email ?? respTaxpayer?.data?.results[0]?.email
        this.documents.customer.state = respCustomers?.data?.results[0]?.state ?? respTaxpayer?.data?.results[0]?.state ?? respCustomers?.data?.results[0]?.extra_addresses.find((item) => item[0] === respCustomers?.data?.results[0]?.address)[1]
        this.documents.customer.country = respCustomers?.data?.results[0]?.country ?? respTaxpayer?.data?.results[0]?.email ?? 'CL'
        this.documents.customer.business_activity = respCustomers?.data?.results[0]?.business_activity ?? respTaxpayer?.data?.results[0] ?? tp?.irs_settings?.cl_activity_description
        if (respCustomers?.data?.results[0]) {
          this.customerAditionals = {
            contacts: ![null, ''].includes(respCustomers?.data?.results[0]?.contacts[0]) ? respCustomers?.data?.results[0]?.contacts : [],
            extra_addresses: respCustomers?.data?.results[0]?.extra_addresses ?? [],
            business_activities: respCustomers?.data?.results[0]?.business_activities ?? [],
            isCustomer: true
          }
        }
      } else {
        this.customerAditionals = {
          contacts: [],
          extra_addresses: [],
          business_activities: [],
          isCustomer: false
        }
          
        this.resetCustomer += 1
        this.documents.customer = {
          ...this.documents.customer,
          name: '',
          country: null,
          state: '',
          address: '',
          phone: null,
          email: '',
          business_activity: ''
        }
        this.$v.documents.customer.$reset()
        // this.documents.customer.taxpayer = backupTaxpayer
      }
    },
    addDocumentsReference () {
      this.documents.references.push({
        date: null,
        reference_type: '',
        serial: '',
        description: ''
      })
    },
    addDocumentsLine () {
      this.documents.lines.push({
        amount: null,
        exempt: !this.activeDocument.has_taxes,
        discount: 0,
        code: '',
        code_scheme: '',
        discount_scheme: '%',
        name: '',
        quantity: 1,
        tax: null,
        tax_percent: null,
        unit_label: '',
        irs_tax_code: null
      })
    },
    isEmptyRecord (record) {
      return Object.entries(record).every(([key, value]) => {
        if (['ind_global', 'cod_ref'].includes(key)) return true
        return value === null || value === ""
      })
    },
    createDocuments (action = 'pdf') {
      let payload = cloneDeep(this.documents)
      payload.references = payload.references.filter(record => !this.isEmptyRecord(record))

      payload.references?.forEach((item) => {
        item.serial = item.ind_global ? 0 : item.serial
        item.ind_global = item.ind_global ? 1 : 0
        if (item.cod_ref === null) delete item.cod_ref
      })

      if (!payload.number) payload.number = 0
      if (!payload?.collection_method) delete payload.collection_method
      if (!payload?.issuer?.branch) delete payload.issuer
      // pending refactor
      if (Object.prototype.hasOwnProperty.call(payload, 'lines')) {
        Object.defineProperty(payload, 'items', Object.getOwnPropertyDescriptor(payload, 'lines'))
        delete payload.lines
        payload.items.forEach((item) => {
          // item.amount = item.amount.toFixed(6)
          if (!item?.unit_label) delete item.unit_label
          if (!item?.code?.length) {
            delete item.code
            delete item.code_scheme
          } else {
            if (!item?.code_scheme?.length) item.code_scheme = 'INT1'
          }
        })
      }
      
      this.$v.documents.$touch()
      if (['CL110', 'CL111', 'CL112'].includes(this.documents.document_type)) this.$v.exchange.$touch()
      if (this.documents.document_type === 'CL52') this.$v.shipping.$touch()
      if (this.$v.documents.$invalid || (['CL110', 'CL111', 'CL112'].includes(this.documents.document_type) && this.$v.exchange.$invalid) || (this.documents.document_type === 'CL52' && this.$v.shipping.$invalid)) return false

      if (this.documents.document_type === 'CL52') {
        Object.entries(this.shipping).forEach(([k, v]) => {
          if (!v) delete this.shipping[k]
        })
        payload = {...payload, shipping: cloneDeep(this.shipping)}
        if (payload?.shipping.carrier_id) {
          payload.shipping.carrier_id = payload.shipping.carrier_id.split('.').join('')
        }
      }
      if (['CL110', 'CL111', 'CL112'].includes(this.documents.document_type)) {
        payload.customer.state = '-'
        payload = {...payload, exchange: this.exchange}
      } else if (!this.documents.customer.country) {
        payload.customer.country = this.$store.state.auth.account.country
      }

      if (this.documents.discount_scheme === '$') {
        payload.exempt = this.aggregations.exempt
        payload.amount = this.aggregations.amount
      }

      payload.customer.taxpayer = payload.customer.taxpayer.split('.').join('')
      payload.items = payload.items.map(item => {
        return {
          ...item,
          irs_tax_code: item?.irs_tax_code?.id
        }
      })

      payload.items.forEach(item => {
        item.discount = !item.discount ? 0 : item.discount
        if (!item?.irs_tax_code) {
          Object.keys(item).forEach(k => {
            if (k === 'irs_tax_code') delete item[k]
          })
        }
      })

      delete payload.taxpayer

      payload.discount = !payload.discount ? 0 : payload.discount

      this.dialogReviewPDF = true

      if (action === 'create') {
        this.loadingSave = true
        this.$store.dispatch('documents/CREATE', {
          resource: 'documents',
          payload: payload
        })
        .then((response) => {
          this.$router.replace(`/documents/${response.data.id}`)
        })
        .catch((error) => {
          this.$dialog.message.error(this.parseErrors(error.response.data))
        })
        .finally(() => {
          this.loadingSave = false
        })
      } else {
        this.loaderRender = true
        this.$store.dispatch('documents/CREATE', {
          resource: 'documents/pdf_preview',
          payload: payload
        })
        .then((response) => {
          const byteCharacters = atob(response.data.content)
          const byteNumbers = new Array(byteCharacters)
          for (let i = 0; i < byteCharacters.length; i++) {
            byteNumbers[i] = byteCharacters.charCodeAt(i)
          }
          const byteArray = new Uint8Array(byteNumbers)
          const file = new Blob([byteArray], {type: 'application/pdf'})
          this.renderUrlPDF = URL.createObjectURL(file)
          this.$nextTick(() => {
            window.PDFObject.embed(this.renderUrlPDF, '#render')
          })
        })
        .catch((error) => {
          this.$dialog.message.error(this.parseErrors(error.response.data))
        })
        .finally(() => {
          this.loaderRender = false
        })
      }
    },
    setAggregations () {
      // verificar
      const currency = this.currencyDetail(this.documentTypes.find(d => d.id === this.documents.document_type)?.currencies[0])
      const round = currency?.format?.precision === 0
      let exempt = 0
      let amount = 0
      this.aggregations.irs_tax = 0
      this.aggregations.vat_withheld = 0
      this.documents.lines.forEach((line, i) => {
        this.aggregations.lines[i] = {
          subtotal: this.formatNumber((line.amount || 0) * (line.quantity || 0), round),
          totalItem: this.formatNumber((line.amount || 0) * (line.quantity || 0), round)
        }
        if (line.discount) {
          if (line.discount_scheme === '%') {
            this.aggregations.lines[i].subtotal -= this.aggregations.lines[i].subtotal * line.discount / 100
            this.aggregations.lines[i].totalItem = this.aggregations.lines[i].subtotal
          } else {
            this.aggregations.lines[i].discount = this.formatNumber(line.discount, round)
            this.aggregations.lines[i].subtotal -= this.aggregations.lines[i].discount
            this.aggregations.lines[i].totalItem -= this.aggregations.lines[i].discount
          }
        }
        // irs taxes per line
        if (line?.irs_tax_code?.value) {
          if (['15', '30', '31', '32', '33', '34', '36', '37', '38', '39', '41', '47', '48', '49'].includes(line?.irs_tax_code?.id)) {
            if (!line.exempt) {
              this.aggregations.vat_withheld += this.formatNumber((this.aggregations.lines[i].subtotal * line.irs_tax_code.value) / 100, round)
              // this.aggregations.lines[i].subtotal += this.aggregations.vat_withheld
            }
          } else {
            this.aggregations.irs_tax += (this.aggregations.lines[i].subtotal * line.irs_tax_code.value) / 100
           //  this.aggregations.lines[i].subtotal -= this.aggregations.irs_tax
          }
        }

        if (line.exempt || !this.activeDocument.has_taxes) {
          exempt += this.formatNumber(this.aggregations.lines[i].subtotal, round)
        } else {
          amount += this.formatNumber(this.aggregations.lines[i].subtotal, round)
        }
      })
      this.aggregations.exempt = this.formatNumber(exempt, round)
      this.aggregations.amount = this.formatNumber(amount, round)
      this.aggregations.subtotal = exempt + amount
      // discount
      if (this.documents.discount) {
        if (this.documents.discount_scheme === '%') {
          const percent = this.documents.discount / 100
          this.aggregations.amount -= this.formatNumber(this.aggregations.amount * percent, round)
          this.aggregations.exempt -= this.formatNumber(this.aggregations.exempt * percent, round)
        } else if (this.documents.discount_scheme === '$') {
          const amountPercent = this.aggregations.amount * 100 / this.aggregations.subtotal
          const exemptPercent = this.aggregations.exempt * 100 / this.aggregations.subtotal
          this.aggregations.amount -= this.formatNumber(this.documents.discount * amountPercent / 100, round)
          this.aggregations.exempt -= this.formatNumber(this.documents.discount * exemptPercent / 100, round)
        }
      }
      // taxes
      if (this.activeDocument.has_taxes && !this.exempt) {
        if (this.activeDocument.has_lines_total || this.$route.query.origin === 'CL39') {
          const initialAmount = this.aggregations.amount
          this.aggregations.amount = this.formatNumber(initialAmount / ((this.activeDocument.tax / 100) + 1), round)
          this.aggregations.taxes = this.formatNumber(initialAmount - this.aggregations.amount, round)
        } else {
          this.aggregations.taxes = this.formatNumber(this.aggregations.amount * (this.activeDocument.tax / 100), round)
        }
      } else {
        this.aggregations.taxes = 0
      }

      // total
      if (this.aggregations.vat_withheld > 0) {
        this.aggregations.total = this.formatNumber((this.aggregations.amount + this.aggregations.taxes) - this.aggregations.vat_withheld, round)
      } else {
        this.aggregations.total = this.formatNumber(this.aggregations.exempt + this.aggregations.amount + this.aggregations.taxes + this.aggregations.irs_tax, round)
      }
    }
  },
  validations () {
    const documents = {
      document_type: {
        required
      },
      date: {
        required
      },
      period_start: {
        required: requiredIf(function () {
          return this.period_end
        })
      },
      period_end: {
        required: requiredIf(function () {
          return this.period_start
        })
      },
      number: {
        required: requiredIf(function () {
          return this.$store?.state?.auth?.account?.allowed_to_assign
        })
      },
      discount: {
        maxAmount: function () {
          if (this.documents.discount_scheme === '$') {
            return this.documents.discount <= this.aggregations.subtotal
          }
          return true
        },
        maxPercent: function () {
          if (this.documents.discount_scheme === '%') {
            return this.documents.discount <= 100
          }
          return true
        },
        numeric
      },
      lines: {
        $each: {
          amount: {
            decimal,
            required,
            maxLength: maxLength(18)
          },
          code: {
            maxLength: maxLength(35),
            required: requiredIf(function (line) {
              return !!line?.code_scheme?.length
            })
          },
          code_scheme: {
            maxLength: maxLength(10)
          },
          description: {
            maxLength: maxLength(1000)
          },
          discount: {
            maxValue: function (value, line) {
              if (line.discount_scheme === '$') {
                return value <= line.quantity * line.amount
              }
              if (line.discount_scheme === '%') {
                return value <= 100
              }
              return true
            },
            minValue: minValue(0),
            numeric
          },
          name: {
            required,
            maxLength: maxLength(128)
          },
          quantity: {
            required,
            decimal
          },
          unit_label: {
            maxLength: maxLength(4)
          }
        }
      },
      references: {
        $each: {
          date: {
            required: requiredIf(function () {
              return ['CL61', 'CL56', 'CL111', 'CL112'].includes(this.documents.document_type)
            }),
          },
          reference_type: {
            required: requiredIf(function () {
              return ['CL61', 'CL56', 'CL111', 'CL112'].includes(this.documents.document_type)
            }),
          },
          serial: {
            required: requiredIf(function (val) {
              return (!val.ind_global && ['CL61', 'CL56', 'CL111', 'CL112'].includes(this.documents.document_type)) || ['CL61', 'CL56', 'CL111', 'CL112'].includes(this.documents.document_type)
            }),
            maxLength: maxLength(18)
          },
          cod_ref: {
            required: requiredIf(function () {
              return this.documentTypeForOverrideType[this.documents.document_type]?.includes(this.currentDocumentTypeRef)
            })
          },
          description: {
            required: requiredIf(function () {
              return ['CL61', 'CL56', 'CL111', 'CL112'].includes(this.documents.document_type)
            }),
            maxLength: maxLength(64)
          }
        }
      },
      customer: {
        address: {
          required,
          maxLength: maxLength(64)
        },
        country: {
          required: requiredIf(function () {
            return ['CL110', 'CL111', 'CL112'].includes(this.documents.document_type)
          })
        },
        email: {
          email,
          maxLength: maxLength(254)
        },
        name: {
          required,
          maxLength: maxLength(100)
        },
        state: {
          required: requiredIf(function () {
            return !['CL110', 'CL111', 'CL112'].includes(this.documents.document_type)
          })
        },
        taxpayer: {
          required,
          format: function (val) {
            return rutValidator(val)
          },
          maxLength: maxLength(16)
        },
        business_activity: {
          required: requiredIf(function (val) {
            return this.documents.document_type !== 'CL110'
          }),
          maxLength: maxLength(40)
        }
      }
    }
    const shipping = {
      type: {
        required
      },
      address: {
        maxLength: maxLength(70)
      },
      carrier_name: {
        maxLength: maxLength(30)
      },
      carrier_id: {
        format: function (val) {
          return (val.length) ? rutValidator(val) : true
        },
        maxLength: maxLength(16)
      },
      licence_plate: {
        maxLength: maxLength(7)
      }
    }
    const exchange = {
      currency_from: {
        required
      },
      rate: {
        required,
        decimal,
        minValue: minValue(1)
      }
    }
    return {
      documents,
      shipping,
      exchange
    }
  }
}
</script>
<style scoped>
.inf-alert {
  padding-top: 15px;
  margin-bottom: 12px;
}
.v-alert--text.v-alert--outlined:not(.inf-alert){
  border-radius: 10px !important;
}
.v-alert--text:not(.inf-alert):before {
  background-color: #FEF2CC !important;
  opacity: 100;
}
.alert-description {
  color: var(--light-yellow);
  z-index: 0;
}
#product-index {
  padding: 10px;
  border: 1px solid var(--light-blue-500) !important;
}
</style>